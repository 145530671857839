import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const BlogFooter = () => {
  return (
    <Wrapper>
      <Header>Do like all your friends.</Header>
      <Paragraph>
        Join Queue - the social party app. It's free and it’s fucking cool😉
      </Paragraph>
      <StyledLink href="http://onelink.to/pgqs55">
        <Button>Download Now</Button>
      </StyledLink>
    </Wrapper>
  )
}

export default BlogFooter

const Wrapper = styled.div`
  width: 100%;
  height: auto;

  margin-top: 7rem;
  margin-bottom: 130px;

  display: flex;
  flex-direction: column;
`

const Header = styled.h1`
  font-family: "Avenir";
  font-size: 1.7rem;
  font-weight: 700;

  line-height: 1;

  margin-bottom: 0.6rem;
  @media (min-width: 500px) {
    font-size: 2rem;
  }

  @media (min-width: 650px) {
    font-size: 2.2rem;
  }
`

const Paragraph = styled.p`
  font-family: "Avenir";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: lighter;

  line-height: 1.3;

  margin-bottom: 1.5rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
`

const Button = styled.div`
  width: 250px;
  height: 50px;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledLink = styled(props => <a {...props} />)`
  font-family: "Avenir";
  font-size: 1.4rem;
  text-decoration: none;

  color: black;
`
